/* quicksand-300normal - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Quicksand Light '),
    local('Quicksand-Light'),
    url('./files/quicksand-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/quicksand-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* quicksand-400normal - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Quicksand Regular '),
    local('Quicksand-Regular'),
    url('./files/quicksand-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/quicksand-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* quicksand-500normal - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Quicksand Medium '),
    local('Quicksand-Medium'),
    url('./files/quicksand-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/quicksand-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* quicksand-600normal - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Quicksand SemiBold '),
    local('Quicksand-SemiBold'),
    url('./files/quicksand-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/quicksand-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* quicksand-700normal - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Quicksand Bold '),
    local('Quicksand-Bold'),
    url('./files/quicksand-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/quicksand-latin-700.woff') format('woff'); /* Modern Browsers */
}

